import React from 'react';

export function About() {
    return (
        <>
            <div className="uk-section uk-section-muted">
                <div className="uk-container">
                    <div className="uk-h1 uk-text-center">
                        Establecidos en Estados Unidos por m&#xE1;s de 30 a&#xF1;os
                    </div>
                </div>
            </div>
            <div className="uk-section">
                <div className="uk-container">
                    <h1>
                        &#xBF;Qui&#xE9;nes somos?
                    </h1>
                    <div data-uk-grid>
                        <div className="uk-width-1-3@s uk-flex-last@s">
                            <img src="./assets/about-us.jpg" />
                        </div>
                        <div className="uk-width-2-3@s">
                            <p>
                                Sergio Lozano, Fundador de Lozano Travel en Estados Unidos y M&#xE9;xico (Ahora Nuba M&#xE9;xico), lanza Lozano DMC (Destination Management Center), dedicada a facilitar a la Industria del Turismo la oferta de experiencias de viaje en Estados Unidos, mediante programas &#x22;Self-Drive&#x22; en recorridos fuera de lo Ordinario.  Actuamos como tu &#x201C;Sucursal&#x201D; en Estados Unidos, ofreci&#xE9;ndote todos los servicios que necesites, desde Emisi&#xF3;n de Boletos, Traslados, Cruceros cotizados en Estados Unidos, hasta nuestro acceso a Disney Preferred Agents para ayudarte a planear las vacaciones de tus clientes que buscan experiencias de alto nivel.
                            </p>
                            <p>
                                Hemos formado un equipo con experiencia, adem&#xE1;s de asociarnos con proveedores receptivos regionales en este pa&#xED;s, para brindarte un producto competitivo para tus Clientes.
                            </p>
                            <p>
                                Trabajamos &#xFA;nica y exclusivamente con Agencias de Viajes, en formato B2B solamente.  No atendemos Consumidores Directos, no tenemos la capacidad ni la autoridad para hacerlo.
                            </p>
                            <p>
                                Conocemos el producto de Lujo, y el Lujo Moderado para ofrecerte una variedad hotelera competitiva.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-section uk-section-secondary">
                <div className="uk-container">
                    <h2 className="uk-text-center">
                        Tu DMC en Estados Unidos / Especialistas en Programas “Self-Drive”
                    </h2>
                    <p className="uk-text-center">
                        Especializados en parques nacionales, ciudades pequeñas y fascinantes, ricas en historia y gastronomía.
                    </p>
                    <hr />
                    <div className="uk-child-width-1-4@s uk-text-center" data-uk-grid>
                        <div>
                            <h3>
                                Programas "Self-Drive"
                            </h3>
                            <p>
                                Creemos que la mejor manera de visitar la Provincia en Estados Unidos, es salirse de las grandes ciudades, con la libertad de un vehículo de tu elección, para recorrer las bellezas de este país, que son prácticamente infinitas.
                            </p>
                        </div>
                        <div>
                            <h3>
                                La mejor manera de explorar
                            </h3>
                            <p>
                                Selecciona el vehículo para tus Clientes, para que disfruten al máximo su recorrido, planeando con detalles, para no perderse de nada.
                            </p>
                        </div>
                        <div>
                            <h3>
                                Hoteles con Historia
                            </h3>
                            <p>
                                La región de Nueva Inglaterra, es todo un destino histórico y romántico, con paisajes extraordinarios, y una gastronomía para disfrutar.
                            </p>
                        </div>
                        <div>
                            <h3>
                                Gastronomía infinita
                            </h3>
                            <p>
                                Cada región, te ofrece una rica y variada gastronomía, que varía prácticamente de Estado por Estado. Te recomendaremos algunas opciones en tus recorridos, para dejarle un buen sabor de boca a tus Clientes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-section">
                <div className="uk-container">
                    <h2 className="uk-text-center">&#xBF;Por qu&#xE9; Lozano DMC?</h2>
                    <ul className="uk-list uk-list-collapse uk-flex-center uk-grid uk-grid-small uk-text-center" data-uk-margin>
                        <li>
                            <div className="uk-background-muted uk-padding-small uk-border-pill uk-text-lead">Contamos con una gran selección de programas</div>
                        </li>
                        <li>
                            <div className="uk-background-muted uk-padding-small uk-border-pill uk-text-lead">Nuestros proveedores son confiables y seguros</div>
                        </li>
                        <li>
                            <div className="uk-background-muted uk-padding-small uk-border-pill uk-text-lead">Asistencia personalizada en todo momento</div>
                        </li>
                        <li>
                            <div className="uk-background-muted uk-padding-small uk-border-pill uk-text-lead">Experiencia de más de 35 años en la Industria del Viaje</div>
                        </li>
                        <li>
                            <div className="uk-background-muted uk-padding-small uk-border-pill uk-text-lead">Servicio y atención B2B exclusivamente</div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}