import React, { useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import UIkit from 'uikit';
import { SearchForm } from './SearchForm';

export function NavMenu() {
    const offcanvas = useRef();

    const toggleMenu = () => {
        UIkit.offcanvas(offcanvas.current).toggle();
    }

    return (
        <>
            <div data-uk-offcanvas="overlay: true;" ref={offcanvas}>
                <div className="uk-offcanvas-bar uk-flex uk-flex-column">
                    <button className="uk-offcanvas-close" type="button" data-uk-close></button>
                    <ul className="uk-nav uk-nav-primary">
                        <li>
                            <NavLink tag={Link} className="text-dark" to="/" onClick={toggleMenu}>Inicio</NavLink>
                        </li>
                        <li>
                            <NavLink tag={Link} className="text-dark" to="/destinations" onClick={toggleMenu}>Destinos</NavLink>
                        </li>
                        <li>
                            <NavLink tag={Link} className="text-dark" to="/transfers" onClick={toggleMenu}>Traslados</NavLink>
                        </li>
                        <li>
                            <NavLink tag={Link} className="text-dark" to="/aerial" onClick={toggleMenu}>A&#xE9;reo</NavLink>
                        </li>
                        <li>
                            <NavLink tag={Link} className="text-dark" to="/branch" onClick={toggleMenu}>Tu Sucursal en EUA</NavLink>
                        </li>
                        <li>
                            <NavLink tag={Link} className="text-dark" to="/about" onClick={toggleMenu}>Sobre Nosotros</NavLink>
                        </li>
                        <li>
                            <NavLink tag={Link} className="text-dark" to="/contact" onClick={toggleMenu}>Contacto</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="main-navbar-container" data-uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
                <img src="/assets/logo.png" className="uk-display-block uk-responsive-width uk-margin-top uk-margin-bottom uk-margin-auto" />
                <div className="main-navbar">
                    <nav className="uk-container uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                        <a className="uk-navbar-toggle uk-hidden@m" onClick={toggleMenu}>
                            <span data-uk-navbar-toggle-icon></span>
                            <span className="uk-margin-small-left">MENU</span>
                        </a>
                        <div className="uk-navbar-left uk-visible@m">
                            <ul className="uk-navbar-nav">
                                <li>
                                    <NavLink tag={Link} className="text-dark" to="/">Inicio</NavLink>
                                </li>
                                <li>
                                    <NavLink tag={Link} className="text-dark" to="/destinations">Destinos</NavLink>
                                </li>
                                <li>
                                    <NavLink tag={Link} className="text-dark" to="/transfers">Traslados</NavLink>
                                </li>
                                <li>
                                    <NavLink tag={Link} className="text-dark" to="/aerial">A&#xE9;reo</NavLink>
                                </li>
                                <li>
                                    <NavLink tag={Link} className="text-dark" to="/branch">Tu Sucursal en EUA</NavLink>
                                </li>
                                <li>
                                    <NavLink tag={Link} className="text-dark" to="/about">Sobre Nosotros</NavLink>
                                </li>
                                <li>
                                    <NavLink tag={Link} className="text-dark" to="/contact">Contacto</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="uk-navbar-right uk-flex-nowrap">
                            <a className="uk-navbar-toggle" href="#" data-uk-search-icon data-uk-toggle="#search-modal"></a>
                            <div className="uk-navbar-item">
                                <a href="tel:+19566068890" className="uk-button uk-button-link">
                                    <span className="mdi mdi-phone-outline"></span>
                                    <span className="uk-margin-small-left">+1 (956) 606-8890</span>
                                </a>
                            </div>
                            {/*<div className="uk-navbar-item">*/}
                            {/*    <button className="uk-button uk-button-link">*/}
                            {/*        ESP*/}
                            {/*    </button>*/}
                            {/*    |*/}
                            {/*    <button className="uk-button uk-button-link">*/}
                            {/*            ENG*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                        </div>
                    </nav>
                </div>
            </div>
            <SearchForm />
        </>
    );
}
