import React from 'react';
import { Link } from 'react-router-dom';

export function NeedSomethingSection() {
    return (
        <div className="uk-section uk-section-muted">
            <div className="uk-container">
                <div className="uk-flex-middle" data-uk-grid>
                    <div className="uk-width-3-4@s uk-width-4-5@m">
                        {/*<div className="uk-flex-middle" data-uk-grid>*/}
                        {/*    <div className="uk-width-auto@s">*/}
                        {/*        <div className="uk-width-small uk-height-small uk-margin-auto uk-cover-container uk-border-rounded">*/}
                        {/*            <img src="./assets/travel-advisor.jpg" data-uk-cover />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="uk-width-expand@s">*/}
                        <p className="uk-h1">
                            ¡Personaliza tu viaje a tu manera!
                        </p>
                        <p>
                            ¿Necesitas algo más? Contamos con una gran variedad de producto para todo tipo de ocasiones, si no encuentras el servicio que estas buscando, envíanos tu mensaje con lo que necesitas y te atenderemos de inmediato.
                        </p>
                        <div className="uk-grid">
                            <div>
                                <a href="tel:+19566068890" target="_blank" className="uk-button uk-button-link">
                                    <span className="mdi mdi-phone-outline uk-margin-small-right"></span>
                                    +1 (956) 606-8890
                                </a>
                            </div>
                            <div>
                                <a href="https://api.whatsapp.com/send?phone=+19566068890" target="_blank" className="uk-button uk-button-link">
                                    <span className="mdi mdi-whatsapp uk-margin-small-right"></span>
                                    WhatsApp
                                </a>
                            </div>
                            <div>
                                <a href="mailto:infousa@lozanodmc.com" target="_blank" className="uk-button uk-button-link">
                                    <span className="mdi mdi-email-outline uk-margin-small-right"></span>
                                    infousa@lozanodmc.com
                                </a>
                            </div>
                            {/*<div>*/}
                            {/*    <button className="uk-button uk-button-link">*/}
                            {/*        <span className="mdi mdi-chat-outline uk-margin-small-right"></span>*/}
                            {/*        Chat*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                        </div>
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="uk-width-1-4@s uk-width-1-5@m">
                        <Link to="/contact" className="uk-button uk-button-secondary uk-width-1-1">
                            Contactanos
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}