import React from 'react';
import { Footer } from './Footer';
//import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export function Layout({ children }) {
    return (
        <div>
            <NavMenu />
            {/*<Container>*/}
            {children}
            {/*</Container>*/}
            <Footer />
        </div>
    );
}
