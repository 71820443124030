import React from 'react';
import { Link } from 'react-router-dom';

export function DestinationItem({ id, name, image, url, items }) {
    //return (
    //    <Link to={url} className="uk-display-block uk-transition-toggle uk-height-small uk-cover-container">
    //        <img src={image} data-uk-cover />
    //        <div className="uk-position-bottom uk-overlay uk-overlay-default uk-flex uk-flex-center uk-flex-middle">
    //            <h3 className="uk-margin-remove">
    //                {name}
    //            </h3>
    //        </div>
    //    </Link>
    //)

    return (
        <Link to={url} className="uk-link-toggle uk-card uk-card-small uk-card-secondary uk-text-center uk-flex-1">
            <div className="uk-cover-container uk-height-small uk-card-media-top">
                <img src={image} data-uk-cover />
            </div>
            <div className="uk-card-body">
                <h3 className="uk-link-heading uk-margin-small">
                    {name}
                </h3>
                {
                    items
                        ? (
                            <ul className="uk-list uk-list-collapse uk-grid uk-grid-small uk-flex-center uk-text-small">
                                {items.map((m, i) => <li key={`item-${i}`}>{m}</li>)}
                            </ul>
                        )
                        : null
                }
            </div>
        </Link>
    )
}