import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ContactForm } from './ContactForm';

export function Package() {
    let { packageId } = useParams();

    const [pkg, setPackage] = useState();

    const scrollToRequest = () => {
        document.getElementById('QuoteRequest').scrollIntoView({ block: "start", behavior: "smooth" });
    }

    useEffect(() => {
        async function fetchData() {
            fetch(`/packages/get?code=${packageId}`).then(response => response.json()).then(result => {
                setPackage(result[0]);
            });
        }

        fetchData();
    }, [packageId]);

    if (!pkg) {
        return (
            <div className="uk-section uk-section-primary uk-light">
                <div className="uk-container">
                    Loading...
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="uk-section uk-section-primary uk-light">
                <div className="uk-container">
                    <div className="uk-child-width-1-2@s" data-uk-grid>
                        <div className="uk-flex-last@s">
                            {/*<div className="uk-position-relative uk-height-1-1" data-uk-slideshow="autoplay: true; ratio: false; min-height: 200;">*/}
                            {/*    <ul className="uk-slideshow-items uk-height-1-1">*/}
                            {/*        <li>*/}
                            {/*            <img src="./assets/pexels-jimmy-teoh-1634279 1.jpg" alt="" data-uk-cover />*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <img src="./assets/pexels-jimmy-teoh-1634279 1.jpg" alt="" data-uk-cover />*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <img src="./assets/pexels-jimmy-teoh-1634279 1.jpg" alt="" data-uk-cover />*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*    <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>*/}
                            {/*    <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slideshow-item="next"></a>*/}
                            {/*</div>*/}
                            <div className="uk-position-relative uk-height-1-1 uk-cover-container">
                                <img src={`https://lozanodmc.s3.us-east-1.amazonaws.com/itineraries/${pkg.image}`} alt="" data-uk-cover />
                            </div>
                        </div>
                        <div>
                            <span className="uk-label">{pkg.code}</span>
                            <h1 className="uk-margin-remove">
                                {pkg.name}
                            </h1>
                            <div className="uk-margin-small-top uk-margin-bottom uk-grid uk-grid-small">
                                {pkg.price && parseInt(pkg.price) ? <div>Desde {new Intl.NumberFormat(parseInt(pkg.price), { style: 'currency', currency: 'USD' })}</div> : null}
                                {pkg.days && parseInt(pkg.days) ? <div>{parseInt(pkg.days)} Días</div> : null}
                                {
                                    pkg.stars && parseInt(pkg.stars)
                                        ? (
                                            <div>
                                                {new Array(pkg.stars).fill().map(() => <span className="mdi mdi-star"></span>)}
                                            </div>
                                        )
                                        : null
                                }
                            </div>
                            <p>
                                <strong>
                                    {pkg.subtitle}
                                </strong>
                            </p>
                            <p>
                                {pkg.summary}
                            </p>
                            <p>
                                <button className="uk-button uk-button-default uk-button-large" onClick={scrollToRequest}>
                                    Cotizar
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-section">
                <div className="uk-container">
                    <div className="uk-alert">
                        * Este programa es una base, cuyas noches pueden ser extendidas en cada ciudad, según tiempo y gustos de tus clientes.
                    </div>
                    <div className="" data-uk-grid>
                        <div className="uk-width-2-3">
                            {
                                pkg.route
                                    ? (
                                        <>
                                            <h3>
                                                Recorrido:
                                            </h3>
                                            <ul className="uk-list uk-list-bullet">
                                                {pkg.route.map((m, i) => <li key={`item-${i}`}>{m}</li>)}
                                            </ul>
                                        </>
                                    )
                                    : null
                            }
                            {
                                pkg.included
                                    ? (
                                        <>
                                            <h3>
                                                El programa incluye:
                                            </h3>
                                            <ul className="uk-list uk-list-bullet">
                                                {pkg.included.map((m, i) => <li key={`item-${i}`}>{m}</li>)}
                                            </ul>
                                        </>
                                    )
                                    : null
                            }
                            {
                                pkg.not_included
                                    ? (
                                        <>
                                            <h3>
                                                No se incluye:
                                            </h3>
                                            <ul className="uk-list uk-list-bullet">
                                                {pkg.not_included.map((m, i) => <li key={`item-${i}`}>{m}</li>)}
                                            </ul>
                                        </>
                                    )
                                    : null
                            }
                        </div>
                        <div className="uk-width-1-3">
                            <img src={`https://lozanodmc.s3.us-east-1.amazonaws.com/itineraries/${pkg.map}`} />
                            <div className="uk-tile uk-text-center">
                                <p>
                                    Ponte en contacto con nosotros ahora y empieza a planear tu viaje ideal (nuestro consejo es gratis).
                                </p>
                                <button className="uk-button uk-button-primary">
                                    Contacto
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="QuoteRequest" className="uk-section uk-section-muted">
                <div className="uk-container">
                    <h1>Solicita una cotización</h1>
                    <ContactForm package_code={pkg.code} package_name={pkg.name} />
                </div>
            </div>
        </>
    )
}