////import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import '@mdi/font/scss/materialdesignicons.scss';
import './styles/theme.scss';
import './styles/site.scss';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <ScrollToTop />
        <App />
    </BrowserRouter>,
    rootElement);

//registerServiceWorker();

