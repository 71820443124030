import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

function SearchIcon({ status }) {
    if (status == 'typing') {
        return <span className="uk-form-icon" data-uk-icon="pencil"></span>;
    } else if (status == 'error') {
        return <span className="uk-form-icon" data-uk-icon="warning"></span>;
        //} else if (status == 'sending') {
        //    return <span className="uk-form-icon"></span>;
    } else {
        return <span className="uk-form-icon" data-uk-icon="search"></span>;
    }
}

export function SearchForm() {
    const [key, setKey] = useState('');
    const [{ status, result }, setState] = useState('');

    var timer = useRef(null);

    const handleChange = (e) => {
        const key = e.target.value;

        setKey(key);
        startTimer(key);

        setState({ status: 'typing', result });
    }

    const startTimer = (key) => {
        clearTimer();
        timer.current = setTimeout(() => sendForm(key), 1000);
    }

    const clearTimer = () => {
        clearTimeout(timer.current);
        timer.current = null;
    }

    const sendForm = (key) => {
        fetch(`/packages/search?text=${key}`).then(response => response.json()).then(result => {
            setState({ status: '', result });
        }).catch(e => {
            setState({ status: 'error', result: null });
        });

        setState({ status: 'sending', result });
    }

    const closeModal = () => {
        UIkit.modal('#search-modal').hide();
    }

    useEffect(() => {
        UIkit.use(Icons);
    }, []);

    return (
        <div id="search-modal" className="uk-modal-full uk-modal" data-uk-modal>
            <div className="uk-modal-dialog uk-flex" data-uk-height-viewport>
                <div className="uk-flex-1">
                    <button className="uk-modal-close-full" type="button" data-uk-close></button>
                    <div className="uk-section uk-section-large uk-height-1-1">
                        <div className="uk-height-1-1 uk-container uk-container-xsmall uk-flex uk-flex-column">
                            <div className="uk-inline uk-margin uk-width-1-1">
                                <SearchIcon status={status} />
                                <input className="uk-input uk-form-large" type="search" placeholder="Buscar" autoFocus value={key} onChange={handleChange} />
                            </div>
                            <div className="uk-flex-1" data-uk-overflow-auto>
                                {
                                    result
                                        ? (
                                            <ul className="uk-list">
                                                {result.map((m, i) => (
                                                    <li key={`item-${m.code}`}>
                                                        <Link to={`/package/${m.code}`} onClick={closeModal}>
                                                            {m.name}
                                                        </Link>
                                                        <ul className="uk-breadcrumb uk-margin-remove">
                                                            <li><Link to={`/region/${m.id_region}`} onClick={closeModal}>{m.region_name}</Link></li>
                                                            <li><Link to={`/state/${m.id_state}`} onClick={closeModal}>{m.state_name}</Link></li>
                                                            <li><Link to={`/city/${m.id_city}`} onClick={closeModal}>{m.city_name}</Link></li>
                                                        </ul>
                                                    </li>
                                                ))}
                                            </ul>
                                        )
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}