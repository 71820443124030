import React, { useState } from 'react';
import Cleave from 'cleave.js/react';
import UIkit from 'uikit';

export function ContactForm({ package_code, package_name, comments_label, compacted }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [agency_name, setAgency] = useState('');
    const [adults, setAdults] = useState('');
    const [children, setChildren] = useState('');
    const [travelDate, setTravelDate] = useState('');
    const [comments, setComments] = useState('');

    const [status, setStatus] = useState('');

    const sendForm = (e) => {
        e.preventDefault();

        fetch(e.target.action, {
            method: 'post',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                //'Content-Type': 'application/x-www-form-urlencoded'
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name,
                email,
                agency_name,
                phone,
                comments,
                package_code,
                package_name,
                adults,
                children,
                travelDate
            })
        }).then(response => response.json()).then(result => {
            UIkit.modal.alert('¡Tu solicitud se ha enviado!')
        }).catch(e => {
            setStatus('error');
        }).finally(() => {
            setStatus('');
        });

        setStatus('sending');
    }

    return (
        //<div className="uk-section uk-section-muted">
        //<div className="uk-container">
        <div className="" data-uk-grid>
            <div className="uk-width-1-3@s uk-width-1-4@m">
                {/*<div className="uk-grid-small uk-flex-middle" data-uk-grid>*/}
                {/*    <div className="uk-width-auto">*/}
                {/*        <img src="./assets/travel-advisor.jpg" className="uk-preserve-width uk-border-rounded" style={{ width: '100px', height: '100px' }} />*/}
                {/*    </div>*/}
                {/*    <div className="uk-width-expand">*/}
                {/*        <h3>*/}
                {/*            Patricia Vidaurry*/}
                {/*                </h3>*/}
                {/*        <p>*/}
                {/*            Travel Expert*/}
                {/*                </p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="uk-grid-small" data-uk-grid>
                    <div className="uk-width-auto">
                        <span className="mdi mdi-map-marker mdi-24px"></span>
                    </div>
                    <div className="uk-width-expand">
                        Lozano DMC <br />
                        201 W. Del Mar Blvd. – Suite 13 <br />
                        Laredo, Texas 78041 <br />
                        USA
                    </div>
                </div>
                <div className="uk-grid-small" data-uk-grid>
                    <div className="uk-width-auto">
                        <span className="mdi mdi-phone mdi-24px"></span>
                    </div>
                    <div className="uk-width-expand">
                        <strong>+1 (956) 606-8890</strong>
                        <div className="uk-grid uk-grid-small">
                            <div>
                                <a href="tel:+19566068890" target="_blank" className="uk-button uk-button-link">
                                    <small>
                                        Llamar
                                    </small>
                                </a>
                            </div>
                            <div>
                                <a href="https://api.whatsapp.com/send?phone=+19566068890" target="_blank" className="uk-button uk-button-link">
                                    <small>
                                        WhatsApp
                                    </small>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-grid-small" data-uk-grid>
                    <div className="uk-width-auto">
                        <span className="mdi mdi-email mdi-24px"></span>
                    </div>
                    <div className="uk-width-expand">
                        <strong>infousa@lozanodmc.com</strong>
                        <div>
                            <a href="mailto:infousa@lozanodmc.com" target="_blank" className="uk-button uk-button-link">
                                <small>
                                    Enviar E-Mail
                                </small>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-width-2-3@s uk-width-3-4@m">
                <form action="/contact/send" className="uk-form-stacked" onSubmit={sendForm}>
                    <div className="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m" data-uk-grid>
                        <div>
                            <label className="uk-form-label">Tu Nombre</label>
                            <input type="text" className="uk-input" value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                        <div>
                            <label className="uk-form-label">Tu E-Mail</label>
                            <input type="email" className="uk-input" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <div>
                            <label className="uk-form-label">Tu Teléfono</label>
                            <Cleave placeholder="123 456 7890" className="uk-input" options={{ delimiter: ' ', blocks: [3, 3, 4] }} value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div>
                            <label className="uk-form-label">Tu Agencia de Viajes</label>
                            <input type="text" className="uk-input" value={agency_name} onChange={(e) => setAgency(e.target.value)} required />
                        </div>
                        {
                            !compacted
                                ? (
                                    <>
                                        <div>
                                            <label className="uk-form-label">Adultos</label>
                                            <input type="number" className="uk-input" min="1" max="99" value={adults} onChange={(e) => setAdults(e.target.value)} />
                                        </div>
                                        <div>
                                            <label className="uk-form-label">Niños</label>
                                            <input type="number" value="0" className="uk-input" min="0" max="99" value={children} onChange={(e) => setChildren(e.target.value)} />
                                        </div>
                                        <div>
                                            <label className="uk-form-label">Fecha del Viaje</label>
                                            <Cleave placeholder="dd-mm-yyyy" className="uk-input" options={{ date: true, delimiter: '-', datePattern: ['d', 'm', 'Y'] }} value={travelDate} onChange={(e) => setTravelDate(e.target.value)} />
                                        </div>
                                    </>
                                )
                                : null
                        }
                        <div className="uk-width-1-1">
                            <label className="uk-form-label">{comments_label || 'Otros requerimientos'}</label>
                            <textarea type="text" className="uk-textarea uk-height-small" value={comments} onChange={(e) => setComments(e.target.value)} />
                        </div>
                    </div>
                    <button type="submit" className="uk-button uk-button-primary uk-margin" disabled={(status == 'sending')}>Enviar</button>
                </form>
            </div>
        </div>
        //</div>
        //</div>
    )
}