import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DestinationItem } from './DestinationItem';
import { NeedSomethingSection } from './NeedSomethingSection';
import { PackageItem } from './PackageItem';

export function State() {
    let { stateId } = useParams();

    const [state, setState] = useState();
    const [cities, setCities] = useState();
    const [packages, setPackages] = useState();
    const [stateimgs, setStateImgs] = useState();

    useEffect(() => {
        async function fetchData() {
            const [result1, result2, result3, result4] = await Promise.all([
                fetch(`/states/get?id_state=${stateId}`),
                fetch(`/cities/get?id_state=${stateId}`),
                fetch(`/packages/get?id_state=${stateId}`),
                fetch(`/states/images?id_state=${stateId}`)
            ]);

            setState(await result1.json());
            setCities(await result2.json());
            setPackages(await result3.json());
            setStateImgs(await result4.json());
        }

        fetchData();
    }, [stateId])

    return (
        <>
            <div className="uk-position-relative">
                <div data-uk-slideshow="autoplay: true; max-height: 350; animation: fade;">
                    <ul className="uk-slideshow-items">
                        {/*<li>*/}
                        {/*    <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">*/}
                        {/*        <img src="./assets/pexels-jimmy-teoh-1634279 1.jpg" alt="" data-uk-cover />*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">*/}
                        {/*        <img src="./assets/pexels-jimmy-teoh-1634279 1.jpg" alt="" data-uk-cover />*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">*/}
                        {/*        <img src="./assets/pexels-jimmy-teoh-1634279 1.jpg" alt="" data-uk-cover />*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {
                            stateimgs && stateimgs.length > 0
                                ? stateimgs.map((m, i) => {
                                    return (
                                        <li key={`item-${i}`}>
                                            <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-center">
                                                <img src={`https://lozanodmc.s3.us-east-1.amazonaws.com/cities/${m.image}`} alt="" data-uk-cover />
                                            </div>
                                        </li>
                                    )
                                })
                                : null
                        }
                    </ul>
                </div>
                {/*<div className="uk-overlay uk-overlay-default uk-position-left uk-flex uk-flex-middle">*/}
                {/*    <div className="uk-width-large uk-responsive-width">*/}
                {/*        <h1>*/}
                {/*            {state ? state[0].name : ''}*/}
                {/*        </h1>*/}
                {/*        <p>*/}
                {/*            {state ? state[0].description : ''}*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="uk-overlay uk-overlay-primary uk-position-bottom-left uk-flex uk-flex-middle uk-light">
                    <div className="uk-width-large uk-responsive-width">
                        <h1 className="uk-margin-remove">
                            {state ? state[0].name : ''}
                        </h1>
                        <ul className="uk-breadcrumb uk-margin-remove">
                            <li><Link to="/destinations">Destinos</Link></li>
                            <li><Link to={`/region/${state ? state[0].id_region : ''}`}>{state ? state[0].region_name : ''}</Link></li>
                            <li><span>{state ? state[0].name : ''}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            {
                !cities
                    ? (
                        <div>Loading...</div>
                    )
                    : (
                        cities.length > 0
                            ? (
                                <div className="uk-section uk-section-muted">
                                    <div className="uk-container uk-container-expand">
                                        <div className="uk-grid-divider uk-flex-middle" data-uk-grid>
                                            <div className="uk-width-auto@s">
                                                <h3>Ciudades</h3>
                                            </div>
                                            <div className="uk-width-expand@s">
                                                <ul className="uk-subnav" data-uk-margin>
                                                    {
                                                        cities.map((m, i) => {
                                                            return (
                                                                <li key={`item-${m.id_city}`}>
                                                                    <Link to={`/city/${m.id_city}`}>{m.name}</Link>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            : null
                    )
            }
            <div className="uk-section">
                <div className="uk-container">
                    <div data-uk-grid>
                        <div className="uk-width-auto@m">
                            <ul className="uk-tab-left" data-uk-tab="connect: .uk-switcher;">
                                <li className="uk-active"><a href="#">Descripción</a></li>
                                <li><a href="#">Programas</a></li>
                            </ul>
                        </div>
                        <div className="uk-width-expand@m">
                            <div className="uk-switcher uk-margin">
                                <div>
                                    {state && state[0].description ? (
                                        <ul className="uk-list uk-list-striped">
                                            {state[0].description.split("\n").map((m, i) => m ? <li key={`item-${i}`}>{m}</li> : null)}
                                        </ul>
                                    ) : ''}
                                </div>
                                <div>
                                    {
                                        !packages
                                            ? (
                                                <div>Loading...</div>
                                            )
                                            : (
                                                <div className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m" data-uk-grid>
                                                    {packages.map((m, i) => {
                                                        return (
                                                            <div key={`item-${m.code}`}>
                                                                <PackageItem pkg={m} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NeedSomethingSection />
        </>
    )
}