import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DestinationItem } from './DestinationItem';
import { NeedSomethingSection } from './NeedSomethingSection';
import { PackageItem } from './PackageItem';

export function Destinations() {
    const [regions, setRegions] = useState();
    const [packages, setPackages] = useState();

    useEffect(() => {
        async function fetchData() {
            const [result1, result2] = await Promise.all([
                fetch('/regions/get'),
                fetch('/packages/get?popular=1')
            ]);

            setRegions(await result1.json());
            setPackages(await result2.json().then(result => result.slice(0, 4)));
        }

        fetchData();
    }, [])

    return (
        <>
            <div className="uk-position-relative">
                <div data-uk-slideshow="autoplay: true; max-height: 350; animation: fade;">
                    <ul className="uk-slideshow-items">
                        <li>
                            <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-center">
                                <img src="./assets/image-1.jpg" alt="" data-uk-cover />
                            </div>
                        </li>
                        <li>
                            <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-center">
                                <img src="./assets/image-2.jpg" alt="" data-uk-cover />
                            </div>
                        </li>
                        <li>
                            <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-center">
                                <img src="./assets/image-3.jpg" alt="" data-uk-cover />
                            </div>
                        </li>
                        <li>
                            <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-center">
                                <img src="./assets/image-4.jpg" alt="" data-uk-cover />
                            </div>
                        </li>
                    </ul>
                </div>
                {/*<div className="uk-overlay uk-overlay-default uk-position-bottom">*/}
                {/*    <div className="uk-container">*/}
                {/*        <h1>*/}
                {/*            Nuestros destinos más populares*/}
                {/*        </h1>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {
                !regions
                    ? (
                        <div>Loading...</div>
                    )
                    : (
                        regions.length > 0
                            ? (
                                <div className="uk-section uk-section-muted">
                                    <div className="uk-container">
                                        <h3>
                                            Regiones
                                        </h3>
                                        <div className="uk-grid-small uk-child-width-1-3@s uk-child-width-1-5@m uk-flex-center" data-uk-grid data-uk-height-match>
                                            {regions.map((m, i) => {
                                                return (
                                                    <div className="uk-flex" key={`item-${m.id_region}`}>
                                                        <DestinationItem
                                                            id="maine"
                                                            name={m.name}
                                                            items={m.states}
                                                            image={`https://lozanodmc.s3.us-east-1.amazonaws.com/cities/${m.image}`}
                                                            url={`/region/${m.id_region}`}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )
                            : null
                    )
            }
            <div className="uk-section">
                <div className="uk-container">
                    <h1>
                        Programas Self-Drive
                    </h1>
                    {
                        !packages
                            ? (
                                <div>Loading...</div>
                            )
                            : (
                                <div className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m uk-flex-center" data-uk-grid>
                                    {packages.map((m, i) => {
                                        return (
                                            <div key={`item-${m.code}`}>
                                                <PackageItem pkg={m} />
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                    }
                </div>
            </div>
            <NeedSomethingSection />
        </>
    )
}