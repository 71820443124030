import React, { Component } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
//import { FetchData } from './components/FetchData';
//import { Counter } from './components/Counter';
import { Destinations } from './components/Destinations';
import { Region } from './components/Region';
import { Package } from './components/Package';
import { Contact } from './components/Contact';
import { City } from './components/City';
import { About } from './components/About';
import { State } from './components/State';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { Aerial } from './components/Aerial';
import { Transfers } from './components/Transfers';
import { Branch } from './components/Branch';

//import './custom.css'

export default class App extends Component {
    render() {
        return (
            <Layout>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/destinations' element={<Destinations />} />
                    <Route path='/region/:regionId' element={<Region />} />
                    <Route path='/state/:stateId' element={<State />} />
                    <Route path='/city/:cityId' element={<City />} />
                    <Route path='/package/:packageId' element={<Package />} />
                    <Route path='/aerial' element={<Aerial />} />
                    <Route path='/branch' element={<Branch />} />
                    <Route path='/transfers' element={<Transfers />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                </Routes>
            </Layout>
        );
    }
}
