import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PackageItem } from './PackageItem';

export function Home() {
    const [packages, setPackages] = useState();

    useEffect(() => {
        async function fetchData() {
            fetch(`/packages/get?popular=1`).then(response => response.json()).then(result => {
                setPackages(result.slice(0, 10));
            });
        }

        fetchData();
    }, []);

    return (
        <>
            {/*<div className="uk-height-large uk-position-relative uk-background-cover" style={{ backgroundImage: 'url(/assets/pexels-jimmy-teoh-1634279%201.jpg)' }} data-uk-parallax="bgy: -300">*/}
            {/*    <div className="uk-light uk-position-bottom-left">*/}
            {/*        <div className="uk-padding">*/}
            {/*            <h1 className="uk-margin-remove uk-heading-medium">New York</h1>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="uk-height-large uk-cover-container">
                <video src="./assets/intro.mp4" loop muted playsInline data-uk-video="autoplay: inview" data-uk-cover></video>
            </div>
            <div className="uk-section uk-section-secondary uk-light">
                <div className="uk-container uk-text-center">
                    <p>
                        <img src="./assets/logo-vertical-small-light.png" />
                    </p>
                    <h1 className="uk-text-uppercase">
                        Establecidos en Estados Unidos por m&#xE1;s de 30 a&#xF1;os
                    </h1>
                    <p className="uk-text-lead">
                        Sergio Lozano, Fundador de Lozano Travel en Estados Unidos y M&#xE9;xico (Ahora Nuba M&#xE9;xico), lanza Lozano DMC (Destination Management Center), dedicada a facilitar a la Industria del Turismo la oferta de experiencias de viaje en Estados Unidos, mediante programas &#x22;Self-Drive&#x22; en recorridos fuera de lo Ordinario.
                    </p>
                    <p>
                        <Link to="/about" className="uk-button uk-button-default">
                            M&#xE1;s sobre nosotros
                        </Link>
                    </p>
                </div>
            </div>
            <section className="uk-section">
                <div className="uk-container">
                    <h1 className="uk-heading-medium uk-text-center uk-margin-large-bottom">
                        Programas Self-Drive
                    </h1>
                    <div className="uk-position-relative" data-uk-slider="autoplay: true">
                        <ul className="uk-slider-items uk-child-width-1-3@s uk-child-width-1-4@m uk-grid">
                            {
                                packages
                                    ? (
                                        packages.map((m, i) => {
                                            return (
                                                <li key={`slide-${i}`}>
                                                    <PackageItem pkg={m} />
                                                </li>
                                            );
                                        })
                                    )
                                    : null
                            }
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}
