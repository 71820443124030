import React, { useState } from 'react';
import Cleave from 'cleave.js/react';
import UIkit from 'uikit';

export function Transfers() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [agency_name, setAgency] = useState('');
    const [passengers, setPassengers] = useState('');
    const [travelDate, setTravelDate] = useState('');
    const [comments, setComments] = useState('');
    const [pickup, setPickUp] = useState('');
    const [dropoff, setDropOff] = useState('');
    const [bags, setBags] = useState('');

    const [status, setStatus] = useState('');

    const sendForm = (e) => {
        e.preventDefault();

        fetch(e.target.action, {
            method: 'post',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                //'Content-Type': 'application/x-www-form-urlencoded'
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name,
                email,
                agency_name,
                phone,
                passengers,
                pickup,
                dropoff,
                bags,
                comments,
                passengers,
                travelDate
            })
        }).then(response => response.json()).then(result => {
            setStatus('');
            UIkit.modal.alert('¡Tu solicitud se ha enviado!')
        }).catch(e => {
            setStatus('error');
        });

        setStatus('sending');
    }

    return (
        <>
            <div className="uk-section">
                <div className="uk-container">
                    <h1 className="uk-text-center">Traslados Privados</h1>
                    {/*<p>Veh&#xED;culos de Lujo:</p>*/}

                    <div className="uk-grid-divider uk-child-width-1-3@s uk-child-width-1-5@m uk-flex-center uk-text-center" data-uk-grid>
                        <div>
                            <p className="uk-text-bold">Veh&#xED;culo Sed&#xE1;n</p>
                            <div className="uk-height-small uk-flex uk-flex-middle">
                                <img src="https://lozanodmc.s3.us-east-1.amazonaws.com/transfers/1%20Sedan%20_%20Mercedes-E-Class.png" />
                            </div>
                            <h3 className="uk-margin-remove">
                                Mercedes E-Class
                                <br />
                                <small>
                                    o Similar
                                </small>
                            </h3>
                            Pasajeros: 3
                            <br />
                            Equipaje: 2
                        </div>
                        <div>
                            <p className="uk-text-bold">Veh&#xED;culo Minivan</p>
                            <div className="uk-height-small uk-flex uk-flex-middle">
                                <img src="https://lozanodmc.s3.us-east-1.amazonaws.com/transfers/2%20Minivan%20_%20Mercedes%20Viano.png" />
                            </div>
                            <h3 className="uk-margin-remove">
                                Mercedes Viano
                                <br />
                                <small>
                                    o Similar
                                </small>
                            </h3>
                            Pasajeros: 5
                            <br />
                            Equipaje: 5
                        </div>
                        <div>
                            <p className="uk-text-bold">Primera Clase</p>
                            <div className="uk-height-small uk-flex uk-flex-middle">
                                <img src="https://lozanodmc.s3.us-east-1.amazonaws.com/transfers/3%20Primera%20clase%20_%20Mercedes%20Benz%20S-Class.png" />
                            </div>
                            <h3 className="uk-margin-remove">
                                Mercedes S-Class
                                <br />
                                <small>
                                    o Similar
                                </small>
                            </h3>
                            Pasajeros: 3
                            <br />
                            Equipaje: 2
                        </div>
                        <div>
                            <p className="uk-text-bold">Veh&#xED;cuos El&#xE9;ctricos *</p>
                            <div className="uk-height-small uk-flex uk-flex-middle">
                                <img src="https://lozanodmc.s3.us-east-1.amazonaws.com/transfers/4%20Electrico%20_%20Tesla%20X.jpg" />
                            </div>
                            <h3 className="uk-margin-remove">
                                Tesla Modelo X
                                <br />
                                <small>
                                    o Similar
                                </small>
                            </h3>
                            Pasajeros: 3
                            <br />
                            Equipaje: 2
                        </div>
                        <div>
                            <p className="uk-text-bold">Sprinter *</p>
                            <div className="uk-height-small uk-flex uk-flex-middle">
                                <img src="https://lozanodmc.s3.us-east-1.amazonaws.com/transfers/5%20Sprinter%20_%20Sprinter%20Class.png" />
                            </div>
                            <h3 className="uk-margin-remove">
                                Mercedes Sprinter
                                <br />
                                <small>
                                    o Similar
                                </small>
                            </h3>
                            Pasajeros: 12
                            <br />
                            Equipaje: 12
                        </div>
                    </div>

                    <p className="uk-text-muted uk-text-center">
                        * Vehículos disponibles en ciudades selectas
                    </p>

                    <div className="uk-tile uk-tile-muted uk-tile-small uk-text-center">
                        Servicio de Emergencia via Whatsapp, en caso de no identificar a su trasladista en Hoteles, Aeropuertos o Estaciones de Tren
                    </div>

                    <h2 className="uk-text-center">Servicios:</h2>
                    <ol className="uk-list uk-list-collapse uk-grid uk-flex-center uk-text-center uk-text-lead" data-uk-margin>
                        <li>Aeropuerto - Hotel o Viceversa</li>
                        <li>Estaci&#xF3;n de Tren - Hotel o Viceversa</li>
                        <li>Ciudad A a Ciudad B</li>
                        <li>Renta por hora con un m&#xED;nimo de 2 horas</li>
                    </ol>
                </div>
            </div>
            <div className="uk-section uk-section-muted">
                <div className="uk-container">
                    <h1>Solicitud de Servicio</h1>
                    <form action="/contact/transfer" method="post" onSubmit={sendForm}>
                        <div className="uk-grid-small uk-child-width-1-3@s uk-child-width-1-5@m uk-form-stacked" data-uk-grid>
                            <div>
                                <label htmlFor="name" className="uk-form-label">Nombre</label>
                                <input id="name" type="text" className="uk-input" value={name} onChange={(e) => setName(e.target.value)} required />
                            </div>
                            <div>
                                <label htmlFor="agency" className="uk-form-label">Nombre de la Agencia</label>
                                <input id="agency" type="text" className="uk-input" value={agency_name} onChange={(e) => setAgency(e.target.value)} required />
                            </div>
                            <div>
                                <label htmlFor="email" className="uk-form-label">Correo Electr&#xF3;nico</label>
                                <input id="email" type="email" className="uk-input" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </div>
                            <div>
                                <label htmlFor="phone" className="uk-form-label">Tel&#xE9;fono</label>
                                <Cleave id="phone" placeholder="123 456 7890" className="uk-input" options={{ delimiter: ' ', blocks: [3, 3, 4] }} value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="date" className="uk-form-label">Fecha de Servicio</label>
                                <Cleave id="date" placeholder="dd-mm-yyyy" className="uk-input" options={{ date: true, delimiter: '-', datePattern: ['d', 'm', 'Y'] }} value={travelDate} onChange={(e) => setTravelDate(e.target.value)} required />
                            </div>
                            <div>
                                <label htmlFor="pickup" className="uk-form-label">Recoger en (Pick-up Location)</label>
                                <input id="pickup" type="text" className="uk-input" value={pickup} onChange={(e) => setPickUp(e.target.value)} required />
                            </div>
                            <div>
                                <label htmlFor="dropoff" className="uk-form-label">Destino (Drop-Off Location)</label>
                                <input id="dropoff" type="text" className="uk-input" value={dropoff} onChange={(e) => setDropOff(e.target.value)} required />
                            </div>
                            <div>
                                <label htmlFor="passengers" className="uk-form-label">N&#xFA;mero de Pasajeros</label>
                                <input id="passengers" type="number" className="uk-input" min="0" max="99" value={passengers} onChange={(e) => setPassengers(e.target.value)} required />
                            </div>
                            <div>
                                <label htmlFor="bags" className="uk-form-label">N&#xFA;mero de Maletas</label>
                                <input id="bags" type="number" className="uk-input" min="0" max="99" value={bags} onChange={(e) => setBags(e.target.value)} required />
                            </div>
                            <div className="uk-width-1-1">
                                <label htmlFor="comments" className="uk-form-label">Comentarios Adicionales</label>
                                <textarea id="comments" className="uk-textarea uk-height-small" value={comments} onChange={(e) => setComments(e.target.value)}></textarea>
                            </div>
                        </div>
                        <button type="submit" className="uk-button uk-button-primary uk-margin" disabled={(status == 'sending')}>Enviar</button>
                    </form>
                </div>
            </div>
        </>
    );
}