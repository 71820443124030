import React from 'react';
import { ContactForm } from './ContactForm';

export function Contact() {
    return (
        <>
            <div className="uk-section">
                <div className="uk-container">
                    {/*<h1>*/}
                    {/*    Tu Sucursal en EUA*/}
                    {/*</h1>*/}
                    {/*<p className="uk-text-lead">*/}
                    {/*    Solic&#xED;tanos cualquier servicio que necesites en Estados Unidos*/}
                    {/*</p>*/}
                    {/*<div className="uk-grid-divider uk-child-width-1-2@s uk-child-width-1-4@m uk-text-center uk-text-lead uk-margin-large" data-uk-grid>*/}
                    {/*    <div>*/}
                    {/*        <svg style={{ width: '100px', height: '100px' }} viewBox="0 0 24 24">*/}
                    {/*            <path fill="#A1A468" d="M13 19C13 18.66 13.04 18.33 13.09 18H3V12H19V13C19.7 13 20.37 13.13 21 13.35V6C21 4.89 20.11 4 19 4H3C1.89 4 1 4.89 1 6V18C1 19.1 1.89 20 3 20H13.09C13.04 19.67 13 19.34 13 19M3 6H19V8H3V6M17.75 22L15 19L16.16 17.84L17.75 19.43L21.34 15.84L22.5 17.25L17.75 22" />*/}
                    {/*        </svg>*/}
                    {/*        <p>Pagos con Tarjetas de cr&#xE9;dito en D&#xF3;lares</p>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <svg style={{ width: '100px', height: '100px' }} viewBox="0 0 24 24">*/}
                    {/*            <path fill="#A1A468" d="M15,14V11H18V9L22,12.5L18,16V14H15M14,7.7V9H2V7.7L8,4L14,7.7M7,10H9V15H7V10M3,10H5V15H3V10M13,10V12.5L11,14.3V10H13M9.1,16L8.5,16.5L10.2,18H2V16H9.1M17,15V18H14V20L10,16.5L14,13V15H17Z" />*/}
                    {/*        </svg>*/}
                    {/*        <p>Pagos con Transferencias a nuestro Banco en Estados Unidos</p>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <svg style={{ width: '100px', height: '100px' }} viewBox="0 0 24 24">*/}
                    {/*            <path fill="#A1A468" d="M18.72,14.76C19.07,13.91 19.26,13 19.26,12C19.26,11.28 19.15,10.59 18.96,9.95C18.31,10.1 17.63,10.18 16.92,10.18C13.86,10.18 11.15,8.67 9.5,6.34C8.61,8.5 6.91,10.26 4.77,11.22C4.73,11.47 4.73,11.74 4.73,12A7.27,7.27 0 0,0 12,19.27C13.05,19.27 14.06,19.04 14.97,18.63C15.54,19.72 15.8,20.26 15.78,20.26C14.14,20.81 12.87,21.08 12,21.08C9.58,21.08 7.27,20.13 5.57,18.42C4.53,17.38 3.76,16.11 3.33,14.73H2V10.18H3.09C3.93,6.04 7.6,2.92 12,2.92C14.4,2.92 16.71,3.87 18.42,5.58C19.69,6.84 20.54,8.45 20.89,10.18H22V14.67H22V14.69L22,14.73H21.94L18.38,18L13.08,17.4V15.73H17.91L18.72,14.76M9.27,11.77C9.57,11.77 9.86,11.89 10.07,12.11C10.28,12.32 10.4,12.61 10.4,12.91C10.4,13.21 10.28,13.5 10.07,13.71C9.86,13.92 9.57,14.04 9.27,14.04C8.64,14.04 8.13,13.54 8.13,12.91C8.13,12.28 8.64,11.77 9.27,11.77M14.72,11.77C15.35,11.77 15.85,12.28 15.85,12.91C15.85,13.54 15.35,14.04 14.72,14.04C14.09,14.04 13.58,13.54 13.58,12.91A1.14,1.14 0 0,1 14.72,11.77Z" />*/}
                    {/*        </svg>*/}
                    {/*        <p>Disney Agents Preferred</p>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <svg style={{ width: '100px', height: '100px' }} viewBox="0 0 24 24">*/}
                    {/*            <path fill="#A1A468" d="M6,6H18V9.96L12,8L6,9.96M3.94,19H4C5.6,19 7,18.12 8,17C9,18.12 10.4,19 12,19C13.6,19 15,18.12 16,17C17,18.12 18.4,19 20,19H20.05L21.95,12.31C22.03,12.06 22,11.78 21.89,11.54C21.76,11.3 21.55,11.12 21.29,11.04L20,10.62V6C20,4.89 19.1,4 18,4H15V1H9V4H6A2,2 0 0,0 4,6V10.62L2.71,11.04C2.45,11.12 2.24,11.3 2.11,11.54C2,11.78 1.97,12.06 2.05,12.31M20,21C18.61,21 17.22,20.53 16,19.67C13.56,21.38 10.44,21.38 8,19.67C6.78,20.53 5.39,21 4,21H2V23H4C5.37,23 6.74,22.65 8,22C10.5,23.3 13.5,23.3 16,22C17.26,22.65 18.62,23 20,23H22V21H20Z" />*/}
                    {/*        </svg>*/}
                    {/*        <p>Reservas de Cruceros por Estados Unidos</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <h3 className="uk-text-center uk-text-muted">
                        Servicio EXCLUSIVO a Agencias de Viajes
                        <br />
                        No damos servicio directo a clientes - Somos exclusivamente B2B
                        </h3>
                </div>
            </div>
            <div className="uk-section uk-section-muted">
                <div className="uk-container">
                    <ContactForm compacted={true} comments_label="Servicio que te interesa" />
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.8816506455605!2d-99.5011948463579!3d27.566183244850997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866120f7cbb9296b%3A0xcf63db0db3c5f0b7!2s201%20W%20Del%20Mar%20Blvd%20%2313%2C%20Laredo%2C%20TX%2078041%2C%20EE.%20UU.!5e0!3m2!1ses-419!2smx!4v1643252453427!5m2!1ses-419!2smx" className="uk-width-1-1 uk-height-large" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
        </>
    )
}