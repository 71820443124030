import React from 'react';
import { Link } from 'react-router-dom';

export function Footer() {
    return (
        <div className="uk-section uk-section-small uk-section-primary">
            <div className="uk-container uk-text-uppercase">
                <div className="uk-flex-middle" data-uk-grid>
                    <div className="uk-width-expand@s uk-text-center">
                        <span className="uk-display-inline-block uk-margin-small-right">{(new Date()).getFullYear()} Todos los derechos reservados</span>
                        <span className="uk-display-inline-block">Lozano DMC</span>
                    </div>
                    <div className="uk-width-auto@s uk-text-center">
                        <img src="./assets/logo-small-light.png" />
                    </div>
                    <div className="uk-width-expand@s uk-text-center">
                        <Link to="/privacy-policy" className="uk-button uk-button-text">Política de Privacidad</Link>
                        {/*<div className="uk-grid uk-grid-small uk-flex-center">*/}
                        {/*    <div className="uk-text-center">*/}
                        {/*        <Link to="/privacy-policy" className="uk-button uk-button-text">Aviso de Privacidad</Link>*/}
                        {/*    </div>*/}
                        {/*    <div className="uk-text-center">*/}
                        {/*        <Link to="/terms-and-conditions" className="uk-button uk-button-text">Términos y Condiciones de Uso</Link>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}