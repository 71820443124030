import React from 'react';
import { Link } from 'react-router-dom';

export function PackageItem({ pkg }) {
    return (
        <div className="uk-card">
            <div className="uk-height-small uk-cover-container">
                <img src={`https://lozanodmc.s3.us-east-1.amazonaws.com/itineraries/${pkg.image}`} data-uk-cover />
            </div>
            <div className="uk-margin">
                <span className="uk-label">{pkg.code}</span>
                <h3 className="uk-card-title uk-text-secondary uk-margin-remove-top">
                    {pkg.name}.
                </h3>
                {
                    pkg.days
                        ? (
                            <p className="uk-text-muted">
                                {pkg.days} Días
                            </p>
                        )
                        : null
                }
                <p>
                    <strong>
                        {pkg.subtitle}
                    </strong>
                    <br />
                    {pkg.summary}
                </p>
                <p>
                    <Link to={`/package/${pkg.code}`} className="uk-button uk-button-default">
                        Ver Programa
                    </Link>
                </p>
            </div>
        </div>
    );
}