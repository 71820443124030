import React from 'react';

export function Aerial() {
    return (
        <div className="uk-section">
            <div className="uk-container">
                <h1 className="uk-text-center">A&#xE9;reo</h1>
                <h3 className="uk-text-center">Te ofrecemos el servicio de emisi&#xF3;n de boletos en Estados Unidos sobre cualquier l&#xED;nea a&#xE9;rea que no puedas emitir en tu pa&#xED;s.</h3>
                <div className="uk-tile uk-tile-small uk-tile-muted uk-text-center">
                    <p className="uk-margin-remove uk-text-large">
                        El cargo por emisi&#xF3;n es de $29.00 USD por PNR Por Persona.
                    </p>
                    Por ejemplo, si en un PNR tienes 2 o m&#xE1;s boleto por emitir, se cobrar&#xE1; un solo fee de $29.00 por todos los boletos, por persona, en ese PNR.
                </div>
                <div className="uk-text-center uk-margin">
                    <p>
                        Para activar este servicio, debes registrar tu Agencia de Viajes como Usuario para emisi&#xF3;n de boletos.
                    </p>
                    <hr className="uk-divider-small" />
                    <p>
                        Muchas rutas a&#xE9;reas cortas emitidas en Estados Unidos cotizan tarifas m&#xE1;s bajas que emitidos en tu Pa&#xED;s.
                    <br />
                    Para verificar la tarifa emitida en Estados Unidos, y tienes SABRE, puedes utlizar el siguiente formato:  WPSLRD#TLRD#MUSD.
                    </p>
                    <hr className="uk-divider-small" />
                    <p className="uk-text-center">Contamos con comisiones a&#xE9;reas en l&#xED;neas a&#xE9;reas originando en Estados Unidos, especialmente en Primera Clase y Business Class.</p>
                </div>
                <h3 className="uk-text-center">Esr&#xED;benos a
                &nbsp;
                <a href="mailto:boletos@lozanodmc.com" className="uk-button uk-button-text uk-text-bold">boletos@lozanodmc.com</a>
                    &nbsp;
                    con copia de tu PNR que quieras emitir, y te notificaremos el monto de comisi&#xF3;n que aplica.</h3>
                {/*<p>Correo: boletos@lozanodmc.com</p>*/}
                <h2 className="uk-text-center">Líneas aéreas participantes:</h2>
                <ol className="uk-list uk-list-collapse uk-grid uk-child-width-1-2 uk-child-width-1-4@s uk-child-width-1-5@m" data-uk-margin>
                    <li>Aer Lingus</li>
                    <li>Aeromexico</li>
                    <li>Air Canada</li>
                    <li>Air France</li>
                    <li>Air New Zealand</li>
                    <li>American Airlines</li>
                    <li>British Airways</li>
                    <li>Cathay Pacific</li>
                    <li>Delta Airlines</li>
                    <li>EgyptAir</li>
                    <li>Emirates Airlines</li>
                    <li>Fiji Aiways</li>
                    <li>Iberia</li>
                    <li>Icelandair</li>
                    <li>Japan Airines</li>
                    <li>KLM</li>
                    <li>Korean Air</li>
                    <li>Lufthansa</li>
                    <li>Qantas</li>
                    <li>Qatar Airways</li>
                    <li>Royal Jordanian</li>
                    <li>Scandinavian</li>
                    <li>Singapore Airlines</li>
                    <li>Swissair</li>
                    <li>Turkish Airlines</li>
                    <li>United Airlines</li>
                    <li>Virgin Atlantic</li>
                </ol>
            </div>
        </div>
    );
}