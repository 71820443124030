import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DestinationItem } from './DestinationItem';
import { NeedSomethingSection } from './NeedSomethingSection';
import { PackageItem } from './PackageItem';

export function City() {
    let { cityId } = useParams();

    const [city, setCity] = useState();
    const [packages, setPackages] = useState();

    useEffect(() => {
        async function fetchData() {
            const [result1, result2] = await Promise.all([
                fetch(`/cities/get?id_city=${cityId}`),
                fetch(`/packages/get?id_city=${cityId}`)
            ]);

            setCity(await result1.json());
            setPackages(await result2.json());
        }

        fetchData();
    }, [cityId])

    return (
        <>
            <div className="uk-position-relative">
                <div data-uk-slideshow="autoplay: true; max-height: 350; animation: fade;">
                    <ul className="uk-slideshow-items">
                        {/*<li>*/}
                        {/*    <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">*/}
                        {/*        <img src="./assets/pexels-jimmy-teoh-1634279 1.jpg" alt="" data-uk-cover />*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">*/}
                        {/*        <img src="./assets/pexels-jimmy-teoh-1634279 1.jpg" alt="" data-uk-cover />*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">*/}
                        {/*        <img src="./assets/pexels-jimmy-teoh-1634279 1.jpg" alt="" data-uk-cover />*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {
                            city && city[0] && city[0].images
                                ? city[0].images.map((m, i) => {
                                    return (
                                        <li key={`item-${i}`}>
                                            <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-center">
                                                <img src={`https://lozanodmc.s3.us-east-1.amazonaws.com/cities/${m}`} alt="" data-uk-cover />
                                            </div>
                                        </li>
                                    )
                                })
                                : null
                        }
                    </ul>
                </div>
                {/*<div className="uk-overlay uk-overlay-default uk-position-left uk-flex uk-flex-middle">*/}
                {/*    <div className="uk-width-large uk-responsive-width">*/}
                {/*        <h1>*/}
                {/*            {city ? city[0].name : ''}*/}
                {/*        </h1>*/}
                {/*        <p>*/}
                {/*            {city ? city[0].description : ''}*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="uk-overlay uk-overlay-primary uk-position-bottom-left uk-flex uk-flex-middle uk-light">
                    <div className="uk-width-large uk-responsive-width">
                        <h1 className="uk-margin-remove">
                            {city ? city[0].name : ''}
                        </h1>
                        <ul className="uk-breadcrumb uk-margin-remove">
                            <li><Link to="/destinations">Destinos</Link></li>
                            <li><Link to={`/region/${city ? city[0].id_region : ''}`}>{city ? city[0].region_name : ''}</Link></li>
                            <li><Link to={`/state/${city ? city[0].id_state : ''}`}>{city ? city[0].state_name : ''}</Link></li>
                            <li><span>{city ? city[0].name : ''}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="uk-section">
                <div className="uk-container">
                    {
                        !packages
                            ? (
                                <div>Loading...</div>
                            )
                            : (
                                <div className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m" data-uk-grid>
                                    {packages.map((m, i) => {
                                        return (
                                            <div key={`item-${m.code}`}>
                                                <PackageItem pkg={m} />
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                    }
                </div>
            </div>
            <NeedSomethingSection />
        </>
    )
}