import React from 'react';

export function PrivacyPolicy() {
    return (
        <>
            <div className="uk-section">
                <div className="uk-container">
                    <h1>
                        POLÍTICA DE PRIVACIDAD
                    </h1>
                    <p>
                        Proteger su información privada es nuestra prioridad. Esta Declaración de privacidad se aplica a Lozano DMC y rige la recopilación y el uso de datos. El sitio web de Lozano DMC es un sitio web de Novedades e Información. Al utilizar el sitio web de Lozano DMC, usted acepta la utilización de los datos descritos en esta norma.
                    </p>
                    <h2>
                        RECOGIDA DE SU INFORMACIÓN PERSONAL
                    </h2>
                    <p>
                        Para brindarle mejor los productos y servicios ofrecidos en nuestro sitio web, Lozano DMC puede recopilar información de identificación personal, como su:
                    </p>
                    <ul>
                        <li>Nombre y Apellido</li>
                        <li>Dirección de correo electrónico</li>
                        <li>Nombre de empresa</li>
                        <li>Nombre de los Viajeros</li>
                        <li>Edad de los viajeros</li>
                        <li>Logo de la compañía</li>
                    </ul>
                    <p>
                        No recopilamos ninguna otra información personal sobre usted o los viajeros a menos que nos la proporcione voluntariamente. Sin embargo, es posible que deba proporcionarnos cierta información personal cuando elija usar ciertos productos o servicios disponibles en el sitio web. Estos pueden incluir: (a) registrarse para obtener una cuenta en nuestro sitio web; (b) enviándonos un mensaje de correo electrónico. A saber, utilizaremos su información para, entre otros, comunicarnos con usted en relación con los servicios y/o productos que nos ha solicitado y agregarlo a nuestra lista de correo. También podemos recopilar información personal o no personal adicional en el futuro.
                    </p>
                    <h2>
                        COMPARTIR INFORMACIÓN CON TERCEROS
                    </h2>
                    <p>
                        Lozano DMC no vende, alquila o cede sus listas de clientes a terceros.
                    </p>
                    <p>
                        Esporádicamente Lozano DMC puede contactarlo de parte de socios de negocios externos para ofrecerle alguna oferta particular que pueda ser de su interés. En esos casos, su información única de identificación personal (correo electrónico, nombre, dirección, número de teléfono) no se transfiere al tercero. Lozano DMC puede compartir datos con socios confiables para ayudar a realizar análisis estadísticos, enviarle correos electrónicos o postales, brindar atención al cliente u organizar entregas. Todos estos terceros tienen prohibido el uso de su información personal, excepto para proporcionar estos servicios a Lozano DMC, y están obligados a mantener la confidencialidad de su información.
                        </p>
                    <p>
                        Lozano DMC puede divulgar su información personal, sin previo aviso, si así lo requiere la ley o si cree de buena fe que dicha acción es necesaria para: (a) cumplir con los edictos de la ley o cumplir con el proceso legal presentado a Lozano DMC o el sitio web; (b) proteger y defender los derechos o propiedad de Lozano DMC; y/o (c) actuar bajo circunstancias exigentes para proteger la seguridad personal de los usuarios de Lozano DMC, o del público.
                    </p>
                    <h2>
                        ¿PUEDE SOLICITARNOS QUE BORREMOS SUS DATOS PERSONALES?
                        </h2>
                    <p>
                        Sí, puede solicitarnos que borremos o eliminemos sus datos personales y Lozano DMC estará más que feliz de realizar estos cambios.
                        </p>
                    <h2>
                        ¿SE PUEDE CAMBIAR ESTA POLÍTICA DE PRIVACIDAD?
                        </h2>
                    <p>
                        Sí, de vez en cuando es posible que tengamos que hacer cambios en esta política de privacidad. Estos pueden ser necesarios como resultado de cambios en las leyes de protección de datos o cuando realicemos cambios en nuestros procedimientos. La última versión de esta política de privacidad estará en nuestro sitio web.
                        </p>
                    <h2>
                        USO DE COOKIES
                        </h2>
                    <p>
                        El sitio web de Lozano DMC puede usar "cookies" para ayudarlo a personalizar su experiencia en línea. Una cookie es un archivo de texto que un servidor de páginas web coloca en su disco duro. Las cookies no se pueden utilizar para ejecutar programas o enviar virus a su computadora. Las cookies se le asignan de forma única y solo puede leerlas un servidor web en el dominio que le emitió la cookie.
                        </p>
                    <p>
                        Tiene la posibilidad de aceptar o rechazar las cookies. La mayoría de los navegadores web aceptan automáticamente las cookies, pero normalmente puede modificar la configuración de su navegador para rechazar las cookies si lo prefiere. Si elige rechazar las cookies, es posible que no pueda experimentar completamente las características interactivas de los servicios o sitios web de Lozano DMC que visita.
                        </p>
                    <h2>
                        ACEPTACIÓN DE LOS TÉRMINOS
                        </h2>
                    <p>
                        Mediante el uso de los sitios web, usted acepta los términos y condiciones estipulados en la Política de Privacidad antes mencionada. Si no está de acuerdo con nuestros términos y condiciones, absténgase de seguir utilizando nuestro sitio web. Además, su uso continuo de nuestro sitio web después de la publicación de cualquier actualización o cambio en nuestros términos y condiciones significará que está de acuerdo y acepta dichos cambios.
                        </p>
                </div>
            </div>
        </>
    )
}