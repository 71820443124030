import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { DestinationItem } from './DestinationItem';
import { NeedSomethingSection } from './NeedSomethingSection';
import { PackageItem } from './PackageItem';

export function Region() {
    let { regionId } = useParams();

    const [region, setRegion] = useState();
    const [states, setStates] = useState();
    const [packages, setPackages] = useState();

    useEffect(() => {
        async function fetchData() {
            const [result1, result2, result3] = await Promise.all([
                fetch(`/regions/get?id_region=${regionId}`),
                fetch(`/states/get?id_region=${regionId}`),
                fetch(`/packages/get?id_region=${regionId}`)
            ]);

            setRegion(await result1.json());
            setStates(await result2.json());
            setPackages(await result3.json());
        }

        fetchData();
    }, [regionId])

    return (
        <>
            {/*<div className="uk-position-relative">
                <div data-uk-slideshow="autoplay: true; max-height: 350">
                    <ul className="uk-slideshow-items">
                        <li>
                            <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">
                                <img src="./assets/pexels-jimmy-teoh-1634279 1.jpg" alt="" data-uk-cover />
                            </div>
                        </li>
                        <li>
                            <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">
                                <img src="./assets/pexels-jimmy-teoh-1634279 1.jpg" alt="" data-uk-cover />
                            </div>
                        </li>
                        <li>
                            <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">
                                <img src="./assets/pexels-jimmy-teoh-1634279 1.jpg" alt="" data-uk-cover />
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="uk-overlay uk-overlay-default uk-position-bottom">
                    <div className="uk-container">
                        <h1>
                            {region ? region[0].name : ''}
                        </h1>
                    </div>
                </div>
            </div>*/}
            <div className="uk-height-large uk-cover-container">
                <img src={`https://lozanodmc.s3.us-east-1.amazonaws.com/cities/${(region ? region[0].image : '')}`} data-uk-cover />
                {/*<div className="uk-overlay uk-overlay-default uk-position-left uk-flex uk-flex-middle">*/}
                {/*    <div className="uk-width-large uk-responsive-width">*/}
                {/*        <h1>*/}
                {/*            {region ? region[0].name : ''}*/}
                {/*        </h1>*/}
                {/*        <p>*/}
                {/*            {region ? region[0].description : ''}*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="uk-overlay uk-overlay-primary uk-position-bottom-left uk-flex uk-flex-middle uk-light">
                    <div className="uk-width-large uk-responsive-width">
                        <h1 className="uk-margin-remove">
                            {region ? region[0].name : ''}
                        </h1>
                        <ul className="uk-breadcrumb uk-margin-remove">
                            <li><Link to="/destinations">Destinos</Link></li>
                            <li><span>{region ? region[0].name : ''}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            {
                !states
                    ? (
                        <div>Loading...</div>
                    )
                    : (
                        states.length > 0
                            ? (
                                //<div className="uk-section uk-section-muted">
                                //    <div className="uk-container">
                                //        <h3>
                                //            Estados
                                //        </h3>
                                //        <div className="uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s uk-flex-center" data-uk-grid>
                                //            {states.map((m, i) => {
                                //                return (
                                //                    <div key={`item-${m.id_state}`}>
                                //                        <DestinationItem id="maine" name={m.name} image={`https://lozanodmc.s3.us-east-1.amazonaws.com/cities/${m.image}`} url={`/state/${m.id_state}`} />
                                //                    </div>
                                //                )
                                //            })}
                                //        </div>
                                //    </div>
                                //</div>
                                <div className="uk-section uk-section-muted">
                                    <div className="uk-container uk-container-expand">
                                        <div className="uk-grid-divider uk-flex-middle" data-uk-grid>
                                            <div className="uk-width-auto@s">
                                                <h3>Estados</h3>
                                            </div>
                                            <div className="uk-width-expand@s">
                                                <ul className="uk-subnav" data-uk-margin>
                                                    {
                                                        states.map((m, i) => {
                                                            return (
                                                                <li key={`item-${m.id_state}`}>
                                                                    <Link to={`/state/${m.id_state}`}>{m.name}</Link>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            : null
                    )
            }
            <div className="uk-section">
                <div className="uk-container">
                    <h1>
                        Servicios destacados
                    </h1>
                    {
                        !packages
                            ? (
                                <div>Loading...</div>
                            )
                            : (
                                <div className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m" data-uk-grid>
                                    {packages.map((m, i) => {
                                        return (
                                            <div key={`item-${m.code}`}>
                                                <PackageItem pkg={m} />
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                    }
                </div>
            </div>
            <NeedSomethingSection />
        </>
    )
}